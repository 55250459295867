<template>
  <div>
      <v-row>
        <v-col  cols="12" md="12">
          <v-select
              clearable
              :items="courseSubjects"
              return-object
              item-value="id"
              item-text="desc"
              label="Select Module (For Capstone Project Teams)"
              solo
              v-model="selectedCourse"
              @change="fetchMyScore"
              @click:clear="removeCourse"
          >
          </v-select>
        </v-col>
      </v-row>

      <div v-if="score && score.team_score ">
        <v-card
            class="mx-auto"
            outlined
        >

            <v-card-text class="title mt-1">
               Scores
            </v-card-text>

            <v-card-text class='d-flex justify-space-between subtitle-1'>
                <span>
                    Team Score for {{score.team_name}}
                </span>
                <span class="title primary--text">
                    {{score.team_score}}
                </span>
            </v-card-text>

            <v-card-text class='d-flex justify-space-between subtitle-1'>
                <span>
                    My Grade
                </span>
                <span class="title primary--text">
                    {{score.student_score}}
                </span>
            </v-card-text>

            <v-divider></v-divider>
            
            <v-card-text>
              <span class="title mt-1">
               Tip.
              </span>
              <v-alert
                border="right"
                colored-border
                type="info"
                elevation="0"
              >
                {{generateMotivationalText(score.sapa_score)}}
              </v-alert>
            </v-card-text>

            <v-card-text v-if='!score.rated_all_peers || score.student_penalty'>
              <span class="title mt-1">
               Penalties Applied.
              </span>
              <ul class="subtitle-1 mt-2">
                <li v-if='!score.rated_all_peers'>A Penalty of 10% has been applied to your score, since did not rate all your peers.</li>
              </ul>
              <ul class="subtitle-1">
                <li v-if='score.student_penalty'>An added Penalty of {{score.student_penalty}}% has been applied to your score by the course administrator.</li>
              </ul>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text class="title mt-1">
                How have your peer(s) rated your participation/contribution (Average rating)?
            </v-card-text>

            <v-card-text v-for="(c, idx) in peerScores" :key="'rating-' + idx" class="d-flex justify-space-between">
                <span class="subtitle-1">
                    {{c.criteriaName}}
                </span>
                <span class="title primary--text bold">
                    {{c.averageScore}} / {{c.weightage}}
                </span>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text class="title mt-1">
                What does your peer have to say about your participation/contribution?
            </v-card-text>

            <v-card-text v-for="(c, idx) in peerReview" :key="'peer-' + idx">
                <ul class="subtitle-1">
                    <li>{{c}}</li>
                </ul>
            </v-card-text>

        </v-card>
      </div>

  </div>
</template>

<script>
import { fetchMyCapstoneScore, fetchCapstonePeerRating } from '../../api/course'

export default {
  components: {
  },
  data() {
    return {
      selectedCourse: {},
      score: {},
      defaultSelected: 1,
      peerScores: []
    }
  },
  mounted(){
  },
  computed: {
    courseSubjects(){    
        let courseSubjects = [];
        
        for(let i = 0; i < this.$store.getters.getCourseSubjects.length; i++){
            let courseSubject = {}
            let current = this.$store.getters.getCourseSubjects[i]

            courseSubject['desc'] = `[${current.subject.subjectName}] ${current.subject.subjectDescription}`
            courseSubject['id'] = current.id

            courseSubjects.push(courseSubject)
        }
        
        return courseSubjects
    },
    selfReview(){

        let comments = JSON.parse(this.score.comment)
        let selfComments = [];
        
        for(let i = 0; i < comments.length; i++){
            if(comments[i].self_comment == 1 && comments[i].comment && comments[i].comment.trim().length > 0){
                selfComments.push(comments[i].comment)  
            }
        }

        if(selfComments.length == 0){
            selfComments.push('N / A')
        }

        return selfComments;
    },
    peerReview(){

        let comments = JSON.parse(this.score.comment)
        let peerComments = [];
        
        for(let i = 0; i < comments.length; i++){
            if(comments[i].self_comment == 0 && comments[i].comment && comments[i].comment.trim().length > 0){
                peerComments.push(comments[i].comment)  
            }
        }

        if(peerComments.length == 0){
            peerComments.push('N / A')
        }

        return peerComments;
    },
  },
  methods:{
    removeCourse(){
      this.score = {}
    },
    async fetchPeerRating(){
      if( this.selectedCourse && this.selectedCourse.id ){
        try{
            const apiData = await fetchCapstonePeerRating({courseSubjectId: this.selectedCourse.id })          
            this.peerScores = apiData.data           
        }
        catch(e){
          this.peerScores = []
          if(e.code === 'ERR_BAD_REQUEST'){
            this.$toast.open({
              message: e.response.data.details || e.response.data.error.message,
              type: 'error',
            });
          }
          else{
            this.$toast.open({
              message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
              type: 'error',
            });
          }
        }
      }
    },
    generateMotivationalText(sapa){
      if(!sapa){
        return 'Please participate in the Self / Peer assessment process. Thanks.'
      }

      if(sapa < 0.95){
        return 'You are doing great. Next time give yourself a better score dring the self assessment!'
      }

      if(sapa > 1.05){
        return 'Your peers have rated you lower. Do introspect and reach out to the course coordinator for tips.'
      }

      return 'Great Job! Your self assessment scores are in line with how you have been perceived by your peers.'
    },
    async fetchMyScore(){
      if( this.selectedCourse && this.selectedCourse.id ){
        try{
            const apiData = await fetchMyCapstoneScore({courseSubjectId: this.selectedCourse.id })          
            this.score = apiData.data[0]
            this.fetchPeerRating()
        }
        catch( e ){
          console.error(e)
          this.score = {}
        }
      }
    },
  },
}
</script>

